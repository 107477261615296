import styled from "styled-components";
import Img from "gatsby-image"
import {typeScale} from "../../utils/elements";

export const ServiceMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 80%;
  margin: 120px auto 20px auto;
  align-items: center;
  height: 70vh;
  
  @media screen and (max-width: 767px) {
    height: 100%;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin: 150px auto 30px auto;
  }
`;

export const ServiceMainText = styled.h3`
  font-size: ${typeScale.header3};
  
  @media screen and (max-width: 767px) {
    font-size: ${typeScale.header4};
  }
  
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: ${typeScale.header4};
  }
`;

export const ServiceMainImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-position: left !important;
`;

export const HeaderThree = styled.h4`
  font-size: ${typeScale.header4};
  transform: translateX(-50px);
  opacity: 0;
  line-height: 190%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @media only screen and (max-width: 600px){
  font-size: ${typeScale.header5};
  }
`;

export const Bar = styled.div`
  @media only screen and (max-width: 600px){
  display: none;
  }
  position: absolute;
  right: -20px;
  height: 100%;
  width: 100px;
  top: 0;
  z-index: -1;
  &::before{
  content: '';
  background-color: rgba(121, 54, 97, .7);
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 1;
  }
  .gatsby-image-wrapper{
  height: 100%;
  width: 100%;
  }
`;