import React, {useEffect, useRef} from 'react';
import {Bar, HeaderThree, ServiceMainContainer, ServiceMainImage} from "./service-main.styles";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {Container} from "../home-about/home-about.style";
import {gsap, Power3} from "gsap/all";

const ServiceMain = () => {
    let headerTitle = useRef(null);
    useEffect(() => {
        gsap.to(headerTitle, {duration: 1, x: 0, opacity: 1, delay: .5, ease: Power3.easeIn})
    })

    const data = useStaticQuery(graphql`
        query {
            crane: file(relativePath: { eq: "crane2.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            decorator: file(relativePath: { eq: "decorator.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <ServiceMainContainer>
            <Container>
                <HeaderThree ref={el => headerTitle = el}>
                    QUBE PCS offers a wide range of professional management services tailored to meet
                    each project’s needs, no matter the size and complexity
                </HeaderThree>
                <Bar>
                    <Img fluid={data.decorator.childImageSharp.fluid}/>
                </Bar>
            </Container>
            <ServiceMainImage fluid={data.crane.childImageSharp.fluid}/>
        </ServiceMainContainer>
    );
};

export default ServiceMain;
