import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceMain from "../components/service-main/service-main.component";
import AllServices from "../components/all-services/all-services.component";


export default () => {

    return (
        <Layout navScroll={false}>
            <SEO title="Services"/>
            <ServiceMain />
            <AllServices />
        </Layout>
    )
}



