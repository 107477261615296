import styled from "styled-components";
import {typeScale} from "../../utils/elements";

export const AllServicesContainer = styled.section`
  height: 100%;
  width: 85%;
  margin: 50px auto 20px auto;
  border-top: 1px solid rgba(0,0,0,0.05);
  h3{
    line-height: 190%;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    width: 90%;
  }
`;

export const ProjectPlanning = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  
  h3 {
    font-size: ${typeScale.header4};
    margin: 70px auto 70px auto;
    
    @media screen and (max-width: 767px) {
      font-size: ${typeScale.header4};
    }
  }
  
  .gatsby-image-wrapper {
    height: 60vh;
    
    @media screen and (max-width: 767px) {
      height: 350px;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      height: 40vh;
    }
  }
`;

export const ConstructionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  
  h3 {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: white;
    z-index: 5;
    width: 50%;
    font-size: ${typeScale.header3};
    
    @media screen and (max-width: 767px) {
      font-size: ${typeScale.header5};
      width: 70%;
      left: 15%;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      width: 80%;
      left: 10%;
    }
  }
  
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 50vh;
    
    @media screen and (max-width: 767px) {
      height: 40vh;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      height: 40vh;
    }
    
    &:after {
      content: "";
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.7;
    }
  }
`;

export const ProjectAdmin = styled.div`
  //width: 80%;
  margin: 70px auto;
  height: 60vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
  border-top: 1px solid rgba(0,0,0,0.05);
  padding-top: 50px;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: 100%;
  }
  
  h3 {
    font-size: ${typeScale.header5};
    line-height: 220%;
    
    @media screen and (max-width: 1200px) {
      font-size: ${typeScale.header5};
    }
  }
  
  .gatsby-image-wrapper {
    height: 100%;
    
    @media screen and (max-width: 767px) {
        height: 80%;
      }
  }
`;

export const FlexGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid rgba(0,0,0,0.05);
  padding-top: 50px;
 
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: 100%;
    margin-bottom: 70px;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    flex-direction: row;
    height: 100%;
  }
`;

export const FlexLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  
  h3 {
    font-size: ${typeScale.header4};
    margin-bottom: 50px;
    
    @media screen and (max-width: 767px) {
      font-size: ${typeScale.header5};
    }
    
    @media screen and (min-width: 768px) and (max-width: 1440px) {
      font-size: ${typeScale.header5};
    }
  }
  
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 50px;
  }
  
  .gatsby-image-wrapper {
    
    @media screen and (max-width: 767px) {
      height: 350px;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1440px) {
      height: 40%;
    }
  }
`;

export const FlexRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  width: 40%;
  margin-left: 10%;
  margin-top: 80px;
  
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-left: unset;
    flex-direction: column;
    margin-top: unset;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1440px) {
      margin-top: 150px;
  }
  
  h3 {
    font-size: ${typeScale.header4};
    margin-top: 50px;
    
    @media screen and (max-width: 767px) {
      font-size: ${typeScale.header5};
      margin-bottom: 40px;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1440px) {
      font-size: ${typeScale.header5};
    }
  }
  
  .gatsby-image-wrapper {
    height: 46%;
    
    @media screen and (max-width: 767px) {
      height: 350px;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1440px) {
      height: 46%;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0,0,0,1.00);
  margin: 50px auto;
`;

export const ServiceHeaders = styled.h3`
  font-size: ${typeScale.header3};
  line-height: 190%;
  text-transform: uppercase;
`;