import React, {useEffect, useRef} from 'react';
import {
    AllServicesContainer,
    ConstructionContainer,
    FlexGrid, FlexLeft, FlexRight,
    ProjectAdmin,
    ProjectPlanning,
} from "./all-services.styles";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {gsap, Power3, ScrollTrigger} from "gsap/all";


export const ServiceParaAnim = (elem) => {
    gsap.from(elem, {
        scrollTrigger: {
            trigger: elem,
            start: 'top bottom',
        }, duration: 1, opacity: 0, y: 50, ease: Power3.easeIn
    })
}

const AllServices = () => {
    let planPara = useRef(null);
    let advisePara = useRef(null);
    let projectPara = useRef(null);
    let constructionPara = useRef(null);
    let adminPara = useRef(null);

    useEffect(() => {
        const adviseImage = document.querySelector('.laptop .gatsby-image-wrapper');
        const projectImage = document.querySelector('.hands .gatsby-image-wrapper');
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        ServiceParaAnim(planPara);
        ServiceParaAnim(advisePara);
        ServiceParaAnim(projectPara);
        ServiceParaAnim(constructionPara);
        ServiceParaAnim(adminPara);

        gsap.from(adviseImage, {
            scrollTrigger: {
                trigger: adviseImage,
                start: 'top center',
            }, duration: 1, opacity: 0, x: 120, delay: .5, ease: Power3.easeInOut
        })
        gsap.from(projectImage, {
            scrollTrigger: {
                trigger: projectImage,
                start: 'top center',
            }, duration: 1, opacity: 0, x: -120, delay: .5, ease: Power3.easeInOut
        })
    })

    const data = useStaticQuery(graphql`
        query {
            airstrip: file(relativePath: { eq: "airport-travel-waiting-terminal-34145.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            map: file(relativePath: { eq: "map.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            plan: file(relativePath: { eq: "plan.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            laptop: file(relativePath: { eq: "john-schnobrich-FlPc9_VocJ4-unsplash.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            hands: file(relativePath: { eq: "stil--837JygbCJo-unsplash.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <AllServicesContainer>
            <ProjectPlanning>
                <h3 ref={el => planPara = el}>Project Planning and Scheduling</h3>
                <Img fluid={data.plan.childImageSharp.fluid}/>
            </ProjectPlanning>

            <FlexGrid>
                <FlexLeft className='laptop'>
                    <h3 ref={el => advisePara = el}>
                        Project Advisory, Consultation & Project Coordination
                    </h3>
                    <Img fluid={data.laptop.childImageSharp.fluid}/>
                </FlexLeft>

                <FlexRight className='hands'>
                    <h3 ref={el => projectPara = el}>
                        Project, Programme Management & Pre-Contract and Post-Contract Management
                    </h3>
                    <Img fluid={data.hands.childImageSharp.fluid}/>
                </FlexRight>
            </FlexGrid>

            <ConstructionContainer>
                <Img fluid={data.airstrip.childImageSharp.fluid}/>
                <h3 ref={el => constructionPara = el}>Construction Management and Site Supervision</h3>
            </ConstructionContainer>

            <ProjectAdmin>
                <h3 ref={el => adminPara = el}>
                    Aviation consultancy with focus on Safety Management Systems, Operational and Technical Management
                    Systems, Environmental Management, Risk Management, Change Management, Audits and Training.
                </h3>
                <Img fluid={data.map.childImageSharp.fluid}/>
            </ProjectAdmin>

        </AllServicesContainer>
    );
};

export default AllServices;
